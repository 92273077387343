<template>
	<div>
		<b-row>
			<b-col>
				<div class="a-profile-subpage-title" :class="`-${userInfo.profile.type}`">
					<div>
						<h1>
							<w-loadable :items="userInfo.profile.type" :msgLoaded="userInfo.profile.type == 'student' ? 'Študent' : 'Učiteľ'" />
						</h1>
						<div class="z-fs-small">{{ $t('Typ registrácie') }}</div>
					</div>
					<div :class="{ 'text-right': userInfo.profile.type == 'teacher' }">
						<time class="fw-bold">{{ moment(userInfo.profile.updated_at).format('DD.MM.YYYY')}}</time>
						<div class="z-fs-small">{{ $t('Posledná aktualizácia') }}</div>
					</div>
					<div v-if="userInfo.profile.type == 'student'">
						<div class="z-icon-wrapper -large">
							<svg-school-icon class="w-color-primary" />
						</div>
						<div class="form-group flex-between mb-0">
							<label for="attendingSchool" class="mr-3" :class="{ 'fw-bold': userInfo.profile.attending_school }">
								{{ $t('Si študent/ka na nejakej škole') }}
							</label>
							<b-form-checkbox v-model="userInfo.profile.attending_school" id="attendingSchool" class="a-checkbox-circle" />
						</div>
					</div>
				</div>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<b-tabs>
					<b-tab :title="$t('Osobné údaje')" active>
						<b-form class="mt-sm-5 mt-3">
							<w-user-info :userInfo="userInfo" />
							<a-zmudri-form :profile="userInfo.profile" :isOnProfilePage="true" />
						</b-form>
						<div class="text-right">
							<b-button type="button" variant="primary" class="-wider" @click.prevent="save">{{ $t('Uložiť zmeny') }}</b-button>
						</div>
					</b-tab>
					<b-tab :title="$t('Zmeniť heslo')" class="changepwdTab">
						<w-changepwd class="mt-sm-5 mt-3" />
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
		<!-- <a-teacher-competition-29-03-2022-modal ref="competition-modal"/> -->
	</div>
</template>

<script>
import lodash from 'lodash'
import moment from 'moment-timezone'
import { mapGetters } from 'vuex'

export default {
	layout: 'profile',

	components: {
		'w-user-info': 												() => import('@/plugins/w@screen/user-settings/w-user-info'),
		'w-changepwd': 												() => import('@/plugins/w@screen/user-settings/w-changepwd'),
		'a-zmudri-form': 											() => import('@/plugins/appzmudri/register/a-zmudri-form'),
		'svg-school-icon':										() => import('@/plugins/appzmudri/_theme/icon/school.svg?inline'),
		// 'a-teacher-competition-29-03-2022-modal':	() => import('./modals/a-teacher-competition-29-03-2022-modal.vue')
	},

	data() {
		return {
			activeTabIndex: 0,
			didntFindMySchool: false,
			userInfo: {
				profile: { 
					type: null,
					attending_school: false,
					city_id: null,
					school_id: null,
					_new_city_name: '',
					_new_school_address: '',
					_new_school_name: '',
					subjects: [],
					subjects_other: '',
					phone: '',
					comment: '',
					state: null,
					is_marketing_accepted: true,
					updated_at: null,
					_lang: this.$root.$i18n.locale
				},
				name: '',
				surname: '',
				email: '',
				is_gdpr_accepted: true
			}
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'isLoggedIn',
			'user'
		]),
		isAdmin() {
			return this.user && this.user.profile && this.user.profile.type == 'teacher'
		},
	},

	watch: {
		'$root.$i18n.locale': {
			handler: function(locale) {
				this.userInfo.profile._lang = locale
			}
		}
	},

	async mounted() {
		try {
			this.userInfo = lodash.merge(this.userInfo, await this.$store.dispatch('wAuth/userInfo'))
		} catch(err) {
			this.userInfo = {error: 'Nepodarilo sa načítať Váš profil...'}
		}

		// this._showCompetitionModal()
	},

	methods: {
		// _showCompetitionModal() {
		// 	setTimeout(() => {
		// 		if (!['/profil', '/pomocnik', '/triedy'].includes(this.$route.path) || !this.isAdmin || !localStorage.getItem('a-teacher-competition-29-03-2022-modal') || localStorage.getItem('a-teacher-competition-29-03-2022-modal') == 'true') {
		// 			return
		// 		}
		// 		this.$refs['competition-modal'].showModal()
		// 		localStorage.setItem('a-teacher-competition-29-03-2022-modal', 'true')
		// 	}, 10000)
		// },
		async save() {
			try {
				await this.$store.dispatch('wAuth/updateUserInfo', this.userInfo)
				this.$wToast.success(this.$t('Zmeny boli uložené'))
			} catch(err) {
				this.$wToast.error(err)
			}
		},
		moment
	}
}
</script>

<style lang="scss" scoped>
.changepwdTab {
	::v-deep button {
		float: right;
	}
}

.a-profile-subpage-title {
	&.-student {
		> div {
			display: flex;

			&:first-of-type {
				padding-right: 4rem;
				flex-direction: column;
				justify-content: center;
				flex: 0 0 30%;
			}

			&:last-of-type {
				padding-left: 2.5rem;
				flex: 1 0 0;
			}

			&:not(:last-of-type):not(:first-of-type) {
				justify-content: center;
				padding: 0 3rem;
				flex: 0 0 30%;
				height: 158px;
				flex-direction: column;
				border-right: 1px solid var(--a-color-blue-border);
				border-left: 1px solid var(--a-color-blue-border);
			}

			@media only screen and (max-width: 991px) {
				&:first-of-type, &:not(:last-of-type):not(:first-of-type) {
					padding: 0;
					flex: 0 0 100%;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
				}

				&:last-of-type {
					padding: 0;
					flex: 0 0 100%;

					.form-group {
						flex: 1 0 0; 
					}
				}

				&:not(:last-of-type):not(:first-of-type) {
					border-left: 0;
					border-right: 0;
					height: auto;
					padding: 1rem 0;
					border-top: 1px solid var(--a-color-blue-border);
					border-bottom: 1px solid var(--a-color-blue-border);
					margin-bottom: 2rem;
				}
			}

			@media only screen and (max-width: 575px) {
				&:first-of-type {
					flex: 0 0 100%;
				}

				&:not(:last-of-type):not(:first-of-type) {
					flex: 0 0 100%;
				}
			}
		}
	}
}

time {
	font-size: 30px;
	font-weight: 600;
}

@media only screen and (max-width: 575px) {
	time {
		font-size: 1.5rem;
	}

	.tabs {
		button, ::v-deep button {
			width: 100%;
			float: none;
		}
	}
}

@media only screen and (min-width: 576px) {
	.z-icon-wrapper {
		&.-large {
			--icon-size: 42px;
		}
	}

	.a-profile-subpage-title {
		&.-student {
			padding: 4.5rem 3rem 4.5rem 3.5rem;
		}
	}
}
</style>